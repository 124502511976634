// Prevent the browser from opening files that are dragged and dropped inside it.
// This prevents conflicts with DnD file uploader.
window.addEventListener(
  'dragover',
  e => {
    e.preventDefault();
  },
  false,
);
window.addEventListener(
  'drop',
  e => {
    e.preventDefault();
  },
  false,
);

// Prevent users from scrolling to increment or decrement a number field.
document.addEventListener('mousewheel', () => {
  if (document.activeElement && document.activeElement.type === 'number') {
    document.activeElement.blur();
  }
});
